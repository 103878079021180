// api.js
import axios from 'axios';

const apiUrl = '';  

const api = axios.create({
  baseURL: apiUrl,
});

export default api;
